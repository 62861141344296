<template>
  <div class="sidong-ring">
    <!-- 搜索 -->
    <div class="bgfff search-wrap">
      <van-search
        v-model="name"
        :left-icon="searchIcon"
        placeholder="请输入私董姓名"
        @blur="searchBlur"
      />
    </div>

    <!-- list -->
    <van-list
      :offset="300"
      v-model="loading"
      :finished="finished"
      finished-text=""
      @load="onLoad"
      class="sidong-list-wrap"
      v-if="sidongList.length"
    >
      <div
        v-for="(item, index) in sidongList"
        :key="index"
        class="sidong-item-wrap bgfff dfc main-between por"
      >
        <button
          class="copy-phone"
          @click="copy"
          :v-text="item.phone"
          :data-clipboard-text="item.phone"
        >
          <div class="dfr cross-center">
            <div class="fs24 fc999">{{ item.phone }}</div>
            <div class="phone-icon ml12">
              <img
                src="../assets/images/sidongRing-phone.png"
                class="w100 h100"
                alt=""
              />
            </div>
          </div>
        </button>
        <div @click="goDetail(item.id)">
          <div class="dfr">
            <!-- 头像 -->
            <div class="item-portrait-box">
              <!-- 用户头像 -->
              <img
                v-if="item.avatar"
                :src="item.avatar"
                alt=""
                class="w100 h100 object-cover"
              />
              <!-- 默认头像 -->
              <img
                v-else
                src="../assets/images/default-portrait.png"
                alt=""
                class="w100 h100"
              />
              <!-- 徽章 -->
              <div
                v-if="item.is_auth || item.is_major_auth"
                class="item-badge-box"
              >
                <img
                  src="../assets/images/home-emblem.png"
                  alt=""
                  class="w100 h100"
                />
              </div>
            </div>
            <!-- 信息 -->
            <div class="dfc ml20 flex1">
              <div class="dfr cross-center main-between">
                <div class="dfr cross-center">
                  <div class="fs32 fc333 fb">{{ item.name }}</div>
                  <div class="name-plate ml20">
                    <name-plate
                      :nameplate="`${item.meeting_user_count}次会`"
                    ></name-plate>
                  </div>
                </div>
              </div>
              <div class="dfr cross-center mt12">
                <div class="fs24 fc999">{{ item.job }}</div>
                <div class="message-line ml12 mr12"></div>
                <div class="fs24 fc999 flex1 text-overflow-one">
                  {{ item.company }}
                </div>
              </div>
            </div>
          </div>
          <div class="dfr flex-wrap mt24">
            <div
              v-for="(_item, _index) in item.field"
              :key="_index"
              class="desc-item mr12 mt12"
            >
              {{ _item.name }}
            </div>
          </div>
        </div>
      </div>
    </van-list>
    <empty v-else></empty>

    <!-- <div style="height:1.2rem"></div> -->

    <!-- button -->
    <div class="b-button-box">
      <div @click="showShare" class="b-button bgfff">
        <icy-button height="0.8rem">
          <div class="button-icon mr12">
            <img :src="buttonIcon" alt="" class="w100 h100" />
          </div>
          <div class="fs28 fb b-text-color">邀请好友加入</div>
        </icy-button>
      </div>
    </div>
  </div>
</template>

<script>
import Clipboard from "clipboard";
export default {
  props: {},
  data() {
    return {
      name: "",
      searchIcon: require("../assets/images/search.png"),
      buttonIcon: require("../assets/images/share-03.png"),
      // 上拉加载
      loading: false,
      finished: false,
      page: 1,
      pageSize: 10,
      currentTotal: "",
      sidongList: [],
      userInfo: "",
      shareLink: ""
    };
  },
  created() {
    this.initPage();
  },
  activated() {
    this.$title("我的私董圈");
  },
  mounted() {},
  methods: {
    async initPage() {
      await this.getUserId();
      this.initShare();
      this.initlist();
    },
    async getUserId() {
      const resp = await this.$API.get("userInfo", false).then(resp => resp);
      if (resp.code == 1000) {
        this.userInfo = resp.data.info;
      }
    },
    initShare() {
      const shareLink =
        location.origin +
        location.pathname +
        `#/login?inviteHostUserId=${this.userInfo.id}`;
      this.shareLink = shareLink;
      let info = "遇事不决开私董，这里能解决你的大部分困惑";
      const shareInfo = {
        title: `${this.userInfo.name}邀请你加入他的私董朋友圈`,
        desc: `${info}`,
        // 朋友圈标题描述
        longTitle: `${info}`,
        link: shareLink,
        /**
           * "http://web.marketinguniversity.cn/share-icon.png",
            "http://sidonghui.shifang.tech/share-icon.png"
           */
        imgUrl: [location.origin + "/share-icon.png"]
      };

      this.$bus.$emit("hasShare", shareInfo);
    },
    showShare() {
      if (this.showShare) {
        this.$bus.$emit("showShareGuide", true);
        this.$bus.$emit("shareGuideHref", this.shareLink);
      }
    },
    // 上拉加载
    async initlist() {
      this.page = 1;
      this.sidongList = await this.getList();
      if (this.currentTotal < this.pageSize) {
        this.loading = false;
        this.finished = true;
      } else {
        this.loading = false;
        this.finished = false;
      }
      this.loading = false;
    },
    async getList() {
      let list = [];
      let data = {
        page: this.page,
        pageSize: this.pageSize,
        keyword: this.name
      };
      const resp = await this.$API
        .post("sidongCircle", data)
        .then(resp => resp);
      if (resp.code == 1000) {
        this.currentTotal = resp.data.list.length;
        list = resp.data.list;
      }
      return list;
    },
    async onLoad() {
      // 触发加载
      if (!this.finished) {
        this.page++;
        const list = await this.getList();
        if (list.length < this.pageSize) {
          this.sidongList = [...this.sidongList, ...list];
          this.loading = false;
          this.finished = true;
        } else {
          this.sidongList = [...this.sidongList, ...list];
          this.loading = false;
          this.finished = false;
        }
      }
    },
    goDetail(id) {
      this.$go("detailS", { id });
    },
    // 搜索
    searchBlur() {
      this.initlist();
    },
    // 电话点击复制
    copy() {
      const clipboard = new Clipboard(".copy-phone");
      clipboard.on("success", () => {
        this.$toast.success("复制成功");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        this.$toast.fail("复制失败");
        // 释放内存
        clipboard.destroy();
      });
    }
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.sidong-ring {
  min-height: 100vh;
  background: #f7f7f7;
  .search-wrap {
    position: sticky;
    top: 0;
    z-index: 10;
  }
  .sidong-list-wrap {
    padding-bottom: 0.28rem;
    .sidong-item-wrap {
      width: 6.94rem;
      min-height: 2.4rem;
      border-radius: 0.12rem;
      margin: 0.28rem auto 0;
      padding: 0.36rem;
      .item-portrait-box {
        width: 0.96rem;
        height: 0.96rem;
        position: relative;
        img {
          border-radius: 50%;
          object-fit: cover;
        }
        .item-badge-box {
          width: 0.28rem;
          height: 0.28rem;
          position: absolute;
          right: 0;
          bottom: 0;
          img {
            position: absolute;
            object-fit: cover;
          }
        }
      }
      .plate-box {
        min-width: 0.76rem;
        height: 0.32rem;
        border-radius: 0.04rem 0.12rem 0.04rem 0.12rem;
        border: 1px solid #ffffff;
        background: linear-gradient(270deg, #d3a96e 0%, #e8caa6 100%);
      }
      .copy-phone {
        margin: 0;
        padding: 0;
        border: 1px solid transparent;
        outline: none;
        background: #fff;
        position: absolute;
        right: 0.36rem;
        top: 0.45rem;
        .phone-icon {
          width: 0.2rem;
          height: 0.24rem;
        }
      }
      .message-line {
        width: 0.02rem;
        height: 0.2rem;
        background: #cccccc;
      }
      .desc-item {
        padding: 0.08rem 0.16rem;
        background: #f6f6f6;
        border-radius: 0.04rem;
      }
    }
  }
  .b-button-box {
    height: calc(1.2rem + env(safe-area-inset-bottom));
    height: calc(1.2rem + constant(safe-area-inset-bottom));
    .b-button {
      position: fixed;
      bottom: 0;
      padding: 0.2rem 0.36rem;
      padding-bottom: calc(0.2rem + env(safe-area-inset-bottom));
      padding-bottom: calc(0.2rem + constant(safe-area-inset-bottom));
      .button-icon {
        width: 0.32rem;
        height: 0.32rem;
      }
      .b-text-color {
        color: #7c5a2f;
      }
    }
  }
}
</style>
